import "typeface-space-mono";
import "./fonts/index.css";
import * as React from "react";
import { hot } from "react-hot-loader";
import { Root, Routes } from "react-static";
import styled, { createGlobalStyle } from "styled-components";
import scrollIntoView from "scroll-into-view-if-needed";
import { Helmet } from "react-helmet";
import { Link } from "@reach/router";
import { withSiteData } from "react-static";
import { ApolloClient } from "apollo-boost";

import { SiteData } from "./Data/SiteData";
import { mkClient } from "./Data/ApolloClient";
import { Context, ColorSourceElement } from "./Context";
import { Information } from "./View/Information";
import { MdMenu } from "react-icons/md";
import { cssFadeIn, cssFadeOut } from "./Animations";
import { globalMargins, DESKTOP_MARGIN } from "./GlobalMargins";
import { navigate, Location, LocationContext } from "@reach/router";
import { ViewLoading } from "./View/Spinner";

/*
/ This file contains components that provide state and rendering context to pages,
/ including the menu / logo and functions to change their color depending on what's underneath.
 *  */

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Space Grotesk", sans-serif;
    width: 100vw;
    overflow-x: hidden;
    font-size: 20px;
    line-height: 1.3;
    font-synthesis: none;
    -webkit-font-smoothing: antialiased;
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  body.hide-overflow {
     overflow-y: hidden;
  }



  a {
     color: inherit;
  }

  .monospace {
     font-family: "Space Mono", monospace;
  }

  .bold {
    font-weight: bold;
  }

  * {
    margin: 0;
    padding: 0;
  }


  p {
    margin-bottom: 1rem;
  }

  .pointer {
    cursor: pointer;
  }
`;

const MOBILE_MENU_TRANSITION_MS = 500;
const MOBILE_HEADER_VH = 10;

const Layout = styled.div`
  &:not(.colorsReady) {
    .menu,
    .logo {
      opacity: 0;
    }
  }

  &.colorsReady {
    .menu,
    .logo {
      opacity: 1;
      transition: opacity 500ms;
    }
  }

  padding-top: ${MOBILE_HEADER_VH}vh;
  .logo:not(.mobile) {
    z-index: 99;
    display: none;
    position: fixed;
    color: transparent;
    margin-top: 2rem;
    cursor: pointer;
    width: 10%;
    left: 0;
    font-size: 1.5rem;
    margin-left: 2rem;
  }

  .logo.mobile {
    font-size: 3.3rem;
    line-height: 1;
    font-weight: bold;
    color: inherit !important;
  }

  .menu {
    a {
      text-decoration: none;
    }
  }

  .menu.mobile {
    a {
      color: inherit !important;
    }
  }

  .menu:not(.mobile) {
    z-index: 99;
    display: none;
    position: fixed;
    top: 8vh;
    width: 10%;
    color: transparent;
    justify-content: center;
    padding-left: 2rem;
    a {
      line-height: 0.5;
      display: block;
      padding-bottom: 1rem;
    }
  }

  .mobileHeader {
    box-shadow: -5px 3px 10px 0px rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 99;
    .header {
      display: flex;
      justify-content: space-between;
      height: ${MOBILE_HEADER_VH}vh;
      align-items: center;
      ${globalMargins} a {
        text-decoration: none;
      }
    }

    .menuWrapper {
      height: ${100 - 2 * MOBILE_HEADER_VH}vh;
      font-size: 3rem;
      padding-top: ${MOBILE_HEADER_VH}vh;
      position: absolute;
      width: 100%;
      top: ${MOBILE_HEADER_VH}vh;
      background: inherit;
      color: inherit;
    }

    .menuWrapper.closed {
      display: none;
    }

    .menuWrapper.open {
      ${cssFadeIn(MOBILE_MENU_TRANSITION_MS)};
    }

    .menuWrapper.closing {
      ${cssFadeOut(MOBILE_MENU_TRANSITION_MS)};
    }

    .menu {
      a {
        display: flex;
        align-items: center;
        height: ${MOBILE_HEADER_VH}vh;
        justify-content: center;
        border-bottom: 1px solid currentColor;
        width: 100%;
      }

      a:first-child {
        border-top: 1px solid currentColor;
      }
    }
  }

  @media (min-width: 1280px) {
    padding-top: 0;
    .mobileHeader {
      display: none;
    }
    .menu:not(.mobile),
    .logo:not(.mobile) {
      display: initial;
    }
  }
`;

type ColorTargetProps = {
  register?: (el: HTMLElement, key: string) => void;
} & React.HTMLAttributes<any>;

type ColorTarget<T = {}> = React.FunctionComponent<ColorTargetProps & T>;

const Logo: ColorTarget = props => {
  return (
    <Link to="/">
      <div
        ref={el => props.register && el && props.register(el, "logo")}
        className={`logo ${props.className || ""}`}
      >RNDR</div>
    </Link>
  );
};

const menuItems = [
  {
    text: "Projects",
    href: "/projects"
  },
  {
    text: "Studio",
    href: "#studio"
  },
  {
    text: "Contact",
    href: "#contact"
  }
];

const Burger = styled.div<{ unit: number; width: number }>`
  width: ${({ width }) => width}px;
  height: ${({ unit }) => unit * 5}px;
  position: relative;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: ${({ unit }) => unit}px;
    width: 100%;
    background: currentColor;
    border-radius: 0px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: ${["transform", "width", "left", "top"]
      .map(it => `${it} ${MOBILE_MENU_TRANSITION_MS / 2}ms ease-in-out`)
      .join(",")};
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: ${({ unit }) => unit * 2}px;
  }

  span:nth-child(4) {
    top: ${({ unit }) => unit * 4}px;
  }

  &.open span:nth-child(1) {
    top: ${({ unit }) => unit * 2}px;
    width: 0%;
    left: 50%;
  }

  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(4) {
    top: ${({ unit }) => unit * 2}px;
    width: 0%;
    left: 50%;
  }
`;

const ViewBurger: React.FunctionComponent<{
  open: boolean;
  onClick: () => void;
  height?: number;
  width?: number;
}> = props => {
  const { height = 30, width = 50 } = props;
  const unit = height / 5;
  return (
    <Burger
      className={props.open ? "open" : ""}
      width={width}
      unit={unit}
      onClick={props.onClick}
    >
      <span />
      <span />
      <span />
      <span />
    </Burger>
  );
};

type MenuState = "closed" | "open" | "closing";

class ViewMobileHeader extends React.Component<
  ColorTargetProps & { menuState: MenuState; toggleMenu: () => void },
  never
> {
  render() {
    return (
      <div
        className="mobileHeader"
        ref={el => this.props.register(el, "mobileHeader")}
      >
        <div className="header">
          <Logo className="mobile" />
          {/* the burger can be enabled when needed */}
          {/* <ViewBurger
              onClick={this.props.toggleMenu}
              open={this.props.menuState === "open"}
              /> */}
        </div>
        <div className={`menuWrapper ${this.props.menuState}`}>
          <Menu className="mobile" />
        </div>
      </div>
    );
  }
}

const Menu: ColorTarget<{ top?: string }> = props => {
  return (
    <div
      style={props.top ? { top: props.top } : {}}
      className={`menu ${props.className || ""}`}
    >
      {menuItems.map((item, i) => {
        const inner = (
          <div
            key={item.href}
            ref={el => props.register && el && props.register(el, item.text)}
          >
            {item.text}
          </div>
        );

        return (
          <a
            key={i}
            className="pointer"
            onClick={() => {
              navigate(item.href);
              if (item.href.includes("#")) {
                const el = document.querySelector(item.href);
                if (el) {
                  scrollIntoView(el);
                }
              } else {
                window.scrollTo(0, 0);
              }
            }}
          >
            {inner}
          </a>
        );
      })}
    </div>
  );
};

const apolloClient = mkClient(
  typeof fetch !== "undefined" ? fetch : (((a: any) => a) as any)
);

type AppProps = {
  children: (args: Context) => JSX.Element;
  siteData: SiteData;
  globalSiteSettings: SiteData.GlobalSEO.GlobalSiteSettings;
};

interface AppState {
  // map to keep track of what the current color of fixed elements should be
  // such as the menu items and the logo
  elementColors: {
    [index: string]: string;
  };
  menuState: MenuState;
  colorsReady: boolean;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      elementColors: {},
      menuState: "closed" as MenuState,
      colorsReady: false
    };
  }

  // map for keeping track of html elements the proximity of which
  // should change the fixed layout elements such as the menu and the logo
  colorSourceElements: {
    [id: string]: {
      element: HTMLElement;
      foreground: string;
      background: string;
    };
  } = {};

  // refs to fixed layout elements such as the menu and the logo
  elementRefs: {
    [index: string]: HTMLElement;
  } = {};

  // a hook that is passed down as part of Context to all child components
  // this allows any component to register itself as a source for changing the current color
  // of the fixed layout elements such as the menu and the logo
  registerColorSourceElement = ({
    id,
    element,
    foreground,
    background
  }: ColorSourceElement) => {
    if (!this.colorSourceElements[id]) {
      this.colorSourceElements[id] = { element, foreground, background };
    }
  };

  registerElement = (el: HTMLElement, key: string) =>
    (this.elementRefs[key] = el);

  mounted = false;

  // the opposite of registerColorSourcElement
  unregisterColorSourceElement = (id: string) => {
    delete this.colorSourceElements[id];
  };

  handleResize = () => {
    this.refreshColors();
    this.alignMenu();
  };

  toggleMenu = () => {
    const nextState = this.state.menuState === "open" ? "closing" : "open";
    const bodyClassList =
      typeof document !== "undefined" && document.body.classList;
    const bodyFn =
      nextState === "open" && bodyClassList
        ? bodyClassList.add
        : bodyClassList.remove;
    bodyFn.apply(bodyClassList, ["hide-overflow"]);

    if (nextState === "closing") {
      setTimeout(() => {
        this.setState({
          menuState: "closed"
        });
      }, MOBILE_MENU_TRANSITION_MS);
    }
    this.setState({
      menuState: nextState
    });
  };

  // recalculate element colors (menu, logo) on scroll
  refreshColors = () => {
    if (this.mounted && this.state.menuState === "closed") {
      const self = this;

      if (
        typeof window !== "undefined" &&
        window.matchMedia("(max-width: 1279px)").matches
      ) {
        const target = self.elementRefs["mobileHeader"];
        if (target) {
          const colorSource = Object.values(self.colorSourceElements)
            .sort((a, b) => {
              const { element: elA } = a;
              const { element: elB } = b;
              const rectA = elA.getBoundingClientRect();
              const rectB = elB.getBoundingClientRect();
              if (rectA.top < rectB.top) {
                return -1;
              }
              if (rectB.top < rectA.top) {
                return 1;
              }
              return 0;
            })
            .find(it => {
              const { element: sourceElement, foreground } = it;
              const sourceRect = sourceElement.getBoundingClientRect();
              const targetRect = target.getBoundingClientRect();
              return sourceRect.bottom >= targetRect.bottom;
            });
          if (colorSource) {
            target.style.backgroundColor = colorSource.background;
            target.style.color = colorSource.foreground;
          }
        }
      } else {

        var i = 0
        // console.log(Object.values(self.colorSourceElements).length);
        Object.values(self.colorSourceElements).forEach(value => {
              const { element: sourceElement, foreground, background } = value;
              const source = sourceElement.getBoundingClientRect();
              
              Object.values(self.elementRefs).forEach(targetElement => {
                
                const target = targetElement.getBoundingClientRect();
                const targetMiddle = target.top + target.height / 2;
                const intersects = source.top < targetMiddle && source.bottom > targetMiddle && source.left == 0;

                if (intersects) {
                  targetElement.style.color = foreground;
                  // console.log(foreground);
                }
              });

        });


      }
      if (!this.state.colorsReady) {
        this.setState({
          colorsReady: true
        });
      }
    } else {
      console.log("frame not mounted yet");
    }
  };

  menuTop: string | null = null;
  alignMenu = () => {
    if (typeof document !== "undefined") {
      const firstTitle = document.querySelector(".project .title");
      const firstKicker = document.querySelector(".project .title");
      
      const menu = document.querySelector(".menu:not(.mobile)") as HTMLElement;

      if (firstTitle && firstKicker && menu) {
        const scrollTop =
          window.scrollY ||
          window.pageYOffset ||
          document.body.scrollTop +
            ((document.documentElement && document.documentElement.scrollTop) ||
              0);

        const y = [firstTitle, firstKicker]
          .map(it => it.getBoundingClientRect().bottom + scrollTop)
          .sort()[0];

        menu.style.top = `calc(${y}px + 2rem)`;
        this.menuTop = menu.style.top;
      } else {
      }
    }
  };

  componentDidMount() {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      document.body.classList.remove("hide-overflow");
      this.mounted = true;
      window.addEventListener("resize", this.handleResize);
      window.addEventListener("scroll", this.refreshColors);
      this.refreshColors();
      this.alignMenu();
      const hash = window.location.hash;
      const el = document.getElementById(hash.replace("#", ""));
      if (el) {
        scrollIntoView(el);
      }
    }
  }

  componentDidUpdate() {
    this.refreshColors();
  }

  componentWillUnmount() {
    this.mounted = false;
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.handleResize);
      window.removeEventListener("scroll", this.refreshColors);
    }
  }

  render() {
    const ctx: Context = {
      registerColorSourceElement: this.registerColorSourceElement,
      unregisterColorSourceElement: this.unregisterColorSourceElement,
      refreshColors: this.refreshColors,
      apolloClient
    };
    const { globalSeo, faviconMetaTags } = this.props.globalSiteSettings;
    const image = `${globalSeo.fallbackSeo.image.url}?w=600`;
    return (
      <Root scrollToTopDuration={0} scrollToHashDuration={0}>
        <Helmet>
          <title>{globalSeo.siteName}</title>
          <meta property="og:title" content={globalSeo.siteName} />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content={globalSeo.fallbackSeo.description}
          />
          <meta property="og:image" content={image} />
          <meta property="og:url" content="https://rndr.studio" />
          <meta property="og:site_name" content={globalSeo.siteName} />
          <meta property="twitter:title" content={globalSeo.siteName} />
          <meta
            property="twitter:description"
            content={globalSeo.fallbackSeo.description}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:image" content={image} />
          <meta
            name="description"
            content={globalSeo.fallbackSeo.description}
          />
          {faviconMetaTags.map((it, i) => {
            return <link key={i} {...it.attributes} />;
          })}
        </Helmet>
        <GlobalStyle />
        <Layout className={(this.state.colorsReady && "colorsReady") || ""}>
          <ViewMobileHeader
            menuState={this.state.menuState}
            toggleMenu={this.toggleMenu}
            register={this.registerElement}
          />
          <Logo register={this.registerElement} />
          <Menu top={this.menuTop} register={this.registerElement} />
          <Context.Provider value={ctx}>
            <Routes>
              {(props: any) => {
                const C = props.getComponentForPath(props.routePath);
                if (C.name === "Spinner") {
                  return (
                    <ViewLoading
                      background="white"
                      spinnerProps={{ color: "black" }}
                    />
                  );
                }
                this.refreshColors();
                this.alignMenu();               
                return (
                  <React.Fragment>
                    <C />
                    <Information
                      ctx={ctx}
                      studioInformation={this.props.siteData.studioInformation}
                      colophonSections={this.props.siteData.colophonSections}
                    />
                  </React.Fragment>
                );
                
              }}
            </Routes>
          </Context.Provider>
        </Layout>
        
      </Root>
    );
  }
}

export default hot(module)(withSiteData(App));
