export function cssFadeOut(time: number = 250) {
  return `
     opacity: 0;
     animation-name: fadeOut;
     animation-iteration-count: 1;
     animation-duration: ${time}ms;
     @keyframes fadeOut {
       0% {
         opacity: 1;
       }
       100% {
        opacity: 0;
       }
     }
  `;
}

export function cssFadeIn(time: number = 250) {
  return `
     opacity: 1;
     animation-name: fadeIn;
     animation-iteration-count: 1;
     animation-duration: ${time}ms;
     @keyframes fadeIn {
       0% {
         opacity: 0;
       }
       100% {
         opacity: 1;
       }
     }
  `;
}
