import ApolloClient from "apollo-boost";
import * as fragmentTypes from "./fragmentTypes.json";
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from "apollo-cache-inmemory";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: (fragmentTypes as any).default
});

const cache = new InMemoryCache({ fragmentMatcher });

type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;

export function mkClient(fetchImpl: Fetch) {
  return new ApolloClient({
    uri: "https://graphql.datocms.com/",
    cache,
    headers: {
      authorization: "Bearer 080b5211b20d3bfe75e98f433a9338"
    },
    fetch: fetchImpl
  });
}
