import * as React from "react";
import * as ReactDOM from "react-dom";

// Your top level component
import App from "./App";

// Export your top level component as JSX (for static rendering)
export default App;

// Render your app
if (typeof document !== "undefined") {
  const renderMethod = (module as any).hot
    ? ReactDOM.render
    : ReactDOM.hydrate || ReactDOM.render;
  const render = (Comp: any) => {
    renderMethod(<Comp />, document.getElementById("root"));
  };

  // Render!
  render(App);
}
