const months: { [index: string]: string } = {
  "01": "January",
  "02": "Feburary",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December"
};

export namespace DateUtil {
  export const YEAR_MONTH = "year-month";
  export const YEAR = "year";
  export type Config = typeof YEAR_MONTH | typeof YEAR;
  export function format(str: string, option: Config = YEAR_MONTH): string {
    const fallback = "-";
    const [year, month] = str.split("-");
    switch (option) {
      case YEAR: {
        if (year) {
          return year;
        }
      }
      case YEAR_MONTH: {
        if (year && month) {
          const formattedMonth = months[month];
          if (formattedMonth) {
            return `${year} ${formattedMonth}`;
          }
        }
      }
    }
    return fallback;
  }
}
