import * as React from "react";
import styled from "styled-components";
import { Link, StudioInformation } from "src/Data/Information";
import { ColophonSection } from "src/Data/Colophon";
import { DateUtil } from "src/DateUtil";
import { Context, ColorSource } from "src/Context";
import { globalMargins } from "src/GlobalMargins";

const BaseContainer = styled.div`
  ${globalMargins}
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: max-content;
    grid-column-gap: 5rem;
  }

  @media (max-width: 1280px) {
    font-size: 1rem;
  }

  .indent {
    @media (min-width: 1280px) {
      padding-left: 2rem;
    }
  }

  h2 {
    padding-bottom: 2rem;
  }
`;

const Studio = styled(BaseContainer)`
  .about {
    @media (min-width: 1280px) {
      grid-column: 1 / span 2;
    }
    padding-bottom: 2rem;
  }

  .events {
    font-size: 0.9rem;
    @media (min-width: 1280px) {
      grid-column: 3 / span 2;
    }

    .listHeader {
      font-weight: 900;
      font-size: 1rem;
      padding-bottom: 1rem;
    }

    .eventCollection {
      padding-bottom: 1rem;
    }
  }
`;

const Contact = styled(BaseContainer)`
  .address {
    p {
      margin: 0;
    }
    @media (min-width: 1280px) {
      grid-column: 1 / span 1;
    }
  }

  .externalLinks {
    padding-bottom: 2rem;
    @media (min-width: 1280px) {
      grid-column: 2 / span 1;
      padding-bottom: 0;
    }
  }

  .openrndrInfo {
    p {
      margin: 0;
    }
    @media (min-width: 1280px) {
      grid-column: 3 / span 1;
      padding-bottom: 0;
    }
  }

  .openrndrLinks {
    @media (min-width: 1280px) {
      grid-column: 4 / span 1;
    }
  }
`;

const viewLinks = (links: Link[]) => {
  return links.map(link => {
    return (
      <div key={link.label}>
        <a target="__blank" href={link.url}>
          {link.label}
        </a>
      </div>
    );
  });
};

export const Information: React.FunctionComponent<{
  studioInformation: StudioInformation;
  colophonSections: ColophonSection[];
  ctx: Context;
}> = props => {
  const { studioInformation: info, colophonSections } = props;
  return (
    <React.Fragment>
      <ColorSource
        nodeId="studio"
        foreground="black"
        background={"rgb(240, 240, 240)"}
        ctx={props.ctx}
      >
        <Studio
          id="studio"
          style={{ minHeight: "30vh", background: "rgb(240, 240, 240)" }}
        >
          <h2 className="indent">Studio</h2>
          <div className="about indent">
            <div dangerouslySetInnerHTML={{ __html: info.about }} />
          </div>
          <div className="events">
            {colophonSections.map(section => {
              return (
                <div key={section.name} className="eventCollection">
                  <div className="bold listHeader">{section.name}</div>
                  <div>
                    {section.items.map(item => {
                      return (
                        <div key={item.id}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.text
                            }}
                          />
                          {item.date && (
                            <React.Fragment>
                              {", "}
                              <span>
                                {DateUtil.format(item.date, DateUtil.YEAR)}
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Studio>
      </ColorSource>
      <ColorSource
        foreground="black"
        background={"#fdd0dd"}
        nodeId="contact"
        ctx={props.ctx}
      >
        <Contact
          id="contact"
          style={{ minHeight: "100vh", background: "#fdd0dd" }}
        >
          <h2 className="indent">Contact</h2>
          <div
            className="address indent"
            dangerouslySetInnerHTML={{ __html: info.address }}
          />
          <div className="externalLinks">
            {[<br key="br" />, ...viewLinks(info.externalLinks)]}
          </div>
          <div
            className="openrndrInfo"
            dangerouslySetInnerHTML={{ __html: info.openrndrInfo }}
          />
          <div className="openrdnrLinks">
            {[<br key="br" />, ...viewLinks(info.openrndrLinks)]}
          </div>
        </Contact>
      </ColorSource>
    </React.Fragment>
  );
};
