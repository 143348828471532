import * as React from "react";
import { ApolloClient } from "apollo-boost";

export interface ColorSourceElement {
  id: string;
  element: HTMLElement;
  foreground: string;
  background: string;
}

export interface ColorSourceElementRegistry {
  registerColorSourceElement: (args: ColorSourceElement) => void;
  unregisterColorSourceElement: (id: string) => void;
  refreshColors: () => void;
}

export type Context = {
  apolloClient: ApolloClient<{}>;
} & ColorSourceElementRegistry;


export const Context = React.createContext<null | Context>(null);

export class ColorSource extends React.Component<
  { ctx: Context; nodeId: string; foreground: string, background: string } & React.HTMLAttributes<any>,
  any
> {
  componentWillUnmount() {
    this.props.ctx.unregisterColorSourceElement(this.props.nodeId);
  }
  render() {
    const { ctx, nodeId, foreground, background, ...rest } = this.props;
    return (
      <div style={{backgroundColor: background}}
        ref={el => {
          el &&
            ctx.registerColorSourceElement &&
            ctx.registerColorSourceElement({
              id: nodeId,
              foreground,
              background,
              element: el
            });
        }}
        {...rest}
      >
        {this.props.children}
      </div>
    );
  }
}
